.about-section {
  padding: 2rem 0;
}

.section-heading {
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.about-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  transition: all 0.3s ease;
}

.about-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px 0 rgba(31, 38, 135, 0.45);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

/* .icon-container {
    background: rgba(0, 115, 255, 0.2);
    border-radius: 50%;
    padding: 1rem;
    margin-bottom: 1.5rem;
  } */

.info-icon {
  font-size: 3rem;
  color: var(--ion-color-primary);
}

.content-container {
  text-align: center;
}

.about-card-p {
  color: #ffffff;
  font-size: 1.1rem !important;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.flip-button {
  --color: #ffffff;
  --background: rgba(0, 115, 255, 0.2);
  --background-hover: rgba(0, 115, 255, 0.3);
  --background-activated: rgba(0, 115, 255, 0.4);
  --border-radius: 25px;
  font-weight: 600;
}

.flip-button ion-icon {
  margin-left: 0.5rem;
}
