.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.section-heading {
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.contact-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

.form-logo {
  width: 200px;
  max-width: 100%;
  margin: 0 auto 2rem;
  display: block;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.contact-info p {
  color: #ffffff;
  font-size: 1.1rem !important;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.contact-action-button {
  --color: #ffffff;
  --color-hover: var(--ion-color-primary);
  --background-hover: rgba(255, 255, 255, 0.1);
  font-weight: bold;
}

.contact-form {
  padding: 2rem;
}

.contact-form-input {
  --background: rgba(255, 255, 255, 0.05);
  --color: #ffffff;
  --placeholder-color: rgba(255, 255, 255, 0.5);
  --border-color: rgba(255, 255, 255, 0.3);
  --border-radius: 10px;
  margin-bottom: 1rem;
}

.submit-button {
  /* --background: #4db6ac;
    --background-hover: #45a49a; */
  --color: #ffffff;
  --border-radius: 10px;
  margin-top: 1rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 1rem;
  }

  .section-heading {
    font-size: 2rem;
  }

  .contact-card {
    margin: 0 -1rem;
    border-radius: 0;
  }

  .contact-info,
  .contact-form {
    padding: 1.5rem;
  }

  .contact-buttons {
    flex-direction: column;
    width: 100%;
  }

  .contact-action-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .contact-form-input {
    font-size: 16px !important; /* Prevents zoom on mobile */
  }

  .submit-button {
    height: 50px;
    font-size: 18px !important;
  }
}
