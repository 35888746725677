.my-toolbar {
    position: absolute;
    top: 90%;
    left: 1%;
    right: 1%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 30%;
    z-index: 1000;
    background-color: #0a0909;
    color: white; /*icon color*/

    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    justify-content: space-between;
    overflow: hidden;
    height: 5%;

}


.my-toolbar ion-buttons {
    display: flex;
    align-items: center;
}

.my-toolbar ion-buttons ion-button {
    margin-left: 10px;
}

/* Add any additional styling as needed */