.home-footer {
    /* background: rgb(6, 6, 6); */
    margin-top: 5%;
    padding-bottom: 5%;
}


.home-footer a{
    color: white;
        /* Remove underlines */
}
.footer-logo{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    padding-top: 3%;
}
/* Media query for phones */
@media only screen and (max-width: 600px) {
   .footer-logo{
    width: auto;
   }
}