.landing-text-card {
    background-color: transparent;
    /* max-width: 40%; */
    top: 0;
    box-shadow: none;
    margin-left: 10vw;
    margin-top: 3%;
    z-index: 5;

}

.landing-text-title {
    font-size: 6vh;
    color: white;
}

.landing-text-subtitle {
    font-size: 4vh;
}