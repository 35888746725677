.services-section {
  padding: 4rem 0;
  /* background: linear-gradient(135deg, #1a1c2d 0%, #2059cb 100%); */
}

.section-heading {
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.service-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  height: 400px;
  perspective: 1000px;
  overflow: hidden;
  transition: all 0.5s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.service-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-back {
  transform: rotateY(180deg);
}

.card-content {
  text-align: center;
  padding: 2rem;
}

.card-icon {
  font-size: 4rem;
  color: var(--ion-color-primary);
  margin-bottom: 1rem;
}

.card-title {
  color: #ffffff;
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
}

.card-description {
  color: #ffffff;
  font-size: 1rem !important;
  line-height: 1.6;
}

.flip-button {
  /* --color: #4db6ac; */
  --background: transparent;
  --background-hover: rgba(77, 182, 172, 0.1);
  --background-activated: rgba(77, 182, 172, 0.2);
  --border-radius: 25px;
  font-weight: 600;
  margin-top: 1rem;
}

.flip-button ion-icon {
  margin-left: 0.5rem;
}
