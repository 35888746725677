.contenu {
    --background: radial-gradient(circle at top left, #4169E1 0%, #000033 30%, #000000 70%, #000033 100%),
                  radial-gradient(circle at top right, #4169E1 0%, #000033 30%, #000000 70%, #000033 100%);
    background-blend-mode: screen;
}

.section-heading {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    color: #ffffff;
}